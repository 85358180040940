<script setup lang="ts">
import { getForm, postForm, Form } from '~/assets/js/forms'
const config = useRuntimeConfig()

const formData = ref<Form|null>(null)
const email = ref('')
const error = ref('')
const success = ref(false)
const formSuccessMessage = computed(() => formData.value?.data?.success.paragraph)
const getFormData = async () => {
	const { data, status } = await getForm(config.public.signUpFormId)
	formData.value = { data, status }
}

const postFormData = async () => {
	const formResponse = await postForm(config.public.signUpFormId, email.value, formData.value?.data?.sections?.[0]?.fields[0]?.id)
	if (formResponse?.status === 'success') {
		success.value = true
		email.value = ''
	} else {
		error.value = formResponse
	}
}

getFormData()
</script>
<template>
	<div class="container">
		<div class="p-6 lg:p-10 bg-mono-50 shadow-lg mb-10">
			<div
				v-if="success"
				class="h-full w-full"
			>
				<h2 class="text-center font-flama text-h4 font-semibold text-primary-600 tracking-[0.21px]">{{ formSuccessMessage }}</h2>
			</div>
			<div
				v-else
				class="flex flex-col lg:items-center gap-4 lg:flex-row lg:gap-[130px]"
			>
				<div class="lg:basis-[431px] lg:shrink-0">
					<h2 class="text-center font-flamaCondensed text-h4 leading-[1.19] lg:text-h3 font-bold text-primary-400 tracking-[0.15px] lg:text-left lg:tracking-[0.3px]">
						{{ formData?.data?.title }}
					</h2>
					<p class="mt-4 text-center text-body-1 leading-[1.2] tracking-[0.53px] lg:text-left">{{ formData?.data?.description }}</p>
				</div>
				<div class="flex flex-col gap-2 basis-full">
					<label class="text-body-1 leading-[23px] text-mono-900">{{ formData?.data?.sections[0].fields[0].label }}</label>
					<div class="flex flex-col gap-5 w-full lg:flex-row">
						<div class="relative w-full">
							<input
								id="email"
								v-model="email"
								type="email"
								name="email"
								placeholder="example@email.com"
								class="w-full h-[55px] px-4 py-[14px] border-2 border-mono-300 text-body-2 leading-[19px]"
							>
							<p
								v-if="error"
								class="mt-2 font-flama text-body-1 text-error lg:absolute lg:left-0 lg:-bottom-8 lg:mt-0"
							>
								{{ error }}
							</p>
						</div>
						<button
							type="submit"
							class="h-[55px] p-4 text-body-1 leading-[1.2] font-flamaCondensed font-bold tracking-[0.3px] bg-primary-600 text-white"
							@click="postFormData"
						>
							SUBMIT
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
