<script setup lang="ts">
import { useHomepageThemeStore } from '~/stores/homepageTheme'
const { getTheme } = useHomepageThemeStore()
useLazyAsyncData('homepage-theme', getTheme)
const { isFinal } = storeToRefs(useHomepageThemeStore())
</script>
<template>
	<div
		:class="[{
			'bg-primary-600 border-highlight': !isFinal,
			'bg-secondary-900 border-secondary-200': isFinal
		}, 'border-b-8 lg:border-b-[16px] pb-4 lg:pb-10 relative']"
	>
		<div
			v-if="isFinal"
			class="h-5/6 lg:h-96 w-full absolute bottom-0 bg-gradient-to-b from-transparent to-secondary-400"
		/>
		<slot/>
	</div>
</template>
