<script setup lang="ts">
const MILLISECONDS_SECOND = 1000
const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND
const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE
const MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR
const EVENT_VISIBILITY_CHANGE = 'visibilitychange'
const INTERVAL = 1000

const props = defineProps({
	time: {
		type: Number,
		default: 0,
		required: true,
		validator: (value: number) => value >= 0,
	},
})

let counting = ref<boolean>(false)
let endTime = ref(0)
let totalMilliseconds = ref<any>(0)
let requestId = ref(0)

const days = computed(() => Math.floor(totalMilliseconds.value / MILLISECONDS_DAY))
const hours = computed(() => Math.floor((totalMilliseconds.value % MILLISECONDS_DAY) / MILLISECONDS_HOUR))
const minutes = computed(() => Math.floor((totalMilliseconds.value % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE))
const seconds = computed(() => Math.floor((totalMilliseconds.value % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND))

onMounted(() => {
	handleStart()
	document.addEventListener(EVENT_VISIBILITY_CHANGE, handleVisibilityChange)
})

onBeforeUnmount(() => {
	document.removeEventListener(EVENT_VISIBILITY_CHANGE, handleVisibilityChange)
	pause()
})

const start = () => {
	if (counting.value) return
	counting.value = true
	if (document.visibilityState === 'visible') {
		continueCountdown()
	}
}

const continueCountdown = () => {
	if (!counting) return
	const delay = Math.min(totalMilliseconds.value, INTERVAL)
	if (delay > 0) {
		let init: number
		let prev: number
		const step = (now: number) => {
			if (!init) {
				init = now
			}
			if (!prev) {
				prev = now
			}
			const range = now - init
			if (
				range >= delay ||
                // Avoid losing time about one second per minute (now - prev ≈ 16ms)
                range + ((now - prev) / 2) >= delay
			) {
				progress()
			} else {
				requestId.value = requestAnimationFrame(step)
			}
			prev = now
		}
		requestId.value = requestAnimationFrame(step)
	} else {
		end()
	}
}

const pause = () => {
	cancelAnimationFrame(requestId.value)
}

const progress = () => {
	if (!counting) return
	totalMilliseconds.value -= INTERVAL
	continueCountdown()
}

const end = () => {
	if (!counting) return
	pause()
	totalMilliseconds.value = 0
	counting.value = false
}

const update = () => {
	if (counting) {
		totalMilliseconds.value = Math.max(0, endTime.value - Date.now())
	}
}

const handleVisibilityChange = () => {
	switch (document.visibilityState) {
	case 'visible':
		update()
		continueCountdown()
		break
	case 'hidden':
		pause()
		break
	default:
	}
}

const transformCoundownSlotProps = (slotProp: number) => {
	return slotProp < 10 ? `0${slotProp}` : String(slotProp)
}

const handleStart = () => {
	totalMilliseconds.value = props.time
	endTime.value = Date.now() + props.time
	start()
}

watch(() => props.time, () => {
	handleStart()
})
</script>

<template>
	<span>
		<slot
			:days="transformCoundownSlotProps(days)"
			:hours="transformCoundownSlotProps(hours)"
			:minutes="transformCoundownSlotProps(minutes)"
			:seconds="transformCoundownSlotProps(seconds)"
		/>
	</span>
</template>
