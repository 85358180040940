import { defineStore } from 'pinia'
import { getArticles } from 'ic-js-content'

export const useHomepageThemeStore = defineStore('homepage-theme', () => {
	let isFinal = ref(false)

	const getTheme = async () => {
		const { data: articleResponseData } = await getArticles({
			params: { tags: ['homepage-theme'], singlePage: true },
		})

		const articleContent = articleResponseData?.articles[0]
		const contentThemeStyle = articleContent?.content[0]?.customContent?.selectTheme_select
		if (contentThemeStyle) {
			isFinal.value = contentThemeStyle === 'Final'
		}
	}

	return {
		getTheme,
		isFinal,
	}
})
